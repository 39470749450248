<template>
    <el-dialog title="Programare" :visible.sync="showPopup" class="my-dialog-class" append-to-body>
        <el-form label-position="top" :inline="false"  label-width="100%" :model='selectedObject' ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >

                <el-col :md="12">
                    <el-form-item prop="DataProgramare" label="Data programare" ref="DataProgramare">
                        <el-date-picker class='full-width' v-model="selectedObject.DataProgramare" type="datetime" value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy'> </el-date-picker>
                    </el-form-item>
                </el-col>

                <el-col :md="12">
                    <el-form-item prop="Mecanic" label="Mecanic" ref="Mecanic">
                        <el-select class='full-width' v-model="selectedObject.Mecanic">
                            <el-option v-for="item in mecanici" :key="item.Id" :value="item.Id" :label="item.Nume"></el-option>
                        </el-select>
                    </el-form-item> 
                </el-col>

            </el-row>
                                                                                     
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"           > Renunta  </el-button>
			<el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
        </span>

    </el-dialog>

    

</template>

<script>
    
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Programare_dialog",
        extends: BasePage,
        data () {
            return {
                showPopup: false,
                selectedObject: {DataProgramare: '', Mecanic: '' },
                oferta_completa: [],
                mecanici: [],
                rules: {
                    DataProgramare: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Mecanic:        [ { required: true, message: 'Campul este obligatoriu' } ], 
                },               
            }
        },
        methods: {
            show_me: function(oferta) {
                this.showPopup          = true;
                this.oferta_completa    = oferta;
            },
            async get_info() {
                var response    = await this.post("oferte/get_info" );
                this.mecanici   = response.mecanici                
            },
            save: async function() 
            {
                console.log(this.selectedObject.DataProgramare)
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        let response = await this.post("oferte/creaza_programare", { object: this.selectedObject, oferta: this.oferta_completa } );
                        this.resetForm()
                        this.$emit("save");
                        this.$message({type: 'info', message: response.mesaj});
                    } else console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
            }
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>