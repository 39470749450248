<template>
        <base-page>
        <titlu-pagina Titlu="Oferte" @on_add_clicked="show_dialog()" :AdaugaVisible="$has_right('vizualizare-oferte')" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :md='4'>
                            <el-form-item label='Masina' >
                                <el-select class='full-width' v-model='Filters.IdMasina' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.masini' :key="'masini' + item.Id" :label='item.Numar' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Client' >
                                <el-select class='full-width' v-model='Filters.IdClient' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.clienti' :key="'clienti' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Data creare' >
                                <el-date-picker v-model='Filters.DataCreare' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Status' >
                                <el-select class='full-width' v-model='Filters.Status' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='in lucru' value='in_lucru'></el-option>
                                    <el-option label='stand by' value='stand-by'></el-option>
                                    <el-option label='trimisa' value='trimisa'></el-option>
                                    <el-option label='respinsa' value='respinsa'></el-option>
                                    <el-option label='aprobata' value='aprobata'></el-option>
                                    <el-option label='amanata' value='amanata'></el-option>
                                    <el-option label='comandata' value='comandata'></el-option>
                                    <el-option label='completa' value='completa'></el-option>
                                    <el-option label='anulata' value='anulata'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Observatii' >
                                <el-input v-model='Filters.Observatii' />
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                            <el-button @click='reset'> Reset </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

<el-table :data="Results" >
    <el-table-column prop='Masina' label='Masina'></el-table-column>
    <el-table-column prop='Client' label='Client'></el-table-column>
        <el-table-column label='Data creare'>
            <template slot-scope='scope'>
                {{ scope.row.DataCreare | momentformat('dd.MM.YYYY') }}
            </template>
        </el-table-column>
        <el-table-column prop='Status' label='Status'></el-table-column>
        <el-table-column prop='Observatii' label='Observatii'></el-table-column>
        <el-table-column fixed="right" label="Actiuni" width="200px" >
            <template slot-scope="scope" >
                <el-tooltip v-if="$has_right('modificare-oferta')" content="Modificare">
                    <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                </el-tooltip>

                <el-tooltip v-if="$has_right('stergere-oferta')" content="Sterge" >
                    <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                </el-tooltip>
            </template>
        </el-table-column>
</el-table>
<el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
<Oferte-dialog ref='dlg' @save="refresh_info()" />
<programare-dlg ref='programare-dlg' @save="refresh_info()"/>
</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Oferte_dialog from '@/pages/oferte/Oferte_dialog.vue';
import Programare_dialog from '@/pages/oferte/Programare_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "oferte",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Oferte-dialog': Oferte_dialog,
        'titlu-pagina': TitluPagina,
        'programare-dlg': Programare_dialog
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                masini: [], clienti: [],             },
            Filters: {
                IdMasina: '-1' , IdClient: '-1' , DataCreare: '' , Status: '-1' , Observatii: '' ,             },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            if (!this.$has_right('vizualizare-oferte')){
                this.$router.push('/no-rights')
                return
            }
            var response        = await this.post("oferte/get_info" );
            this.Info.masini = response.masini;
            //if( this.Filters.IdMasina.length > 0 ) this.Filters.IdMasina = this.Info.masini[0].Id;
            this.Info.clienti = response.clienti;
            //if( this.Filters.IdClient.length > 0 ) this.Filters.IdClient = this.Info.clienti[0].Id;
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("oferte/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('oferte');
        },
        reset(){
            this.Filters = {
                IdMasina: '-1' , IdClient: '-1' , DataCreare: '' , Status: '-1' , Observatii: '' ,             };
            this.refresh_info();
        },

        async delete_item( item ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("oferte/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },

        genereaza_programare(oferta){
            this.$refs['programare-dlg'].show_me(oferta);
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
